<!-- Header Main Area -->
<header class="site-header header-style-1 web-header">
  <div class="pre-header">
    <div class="contaisner">
      <div class="d-flex  row">
        <div class="col-md-6"></div>
        <div class="col-md-3">
          <div class="widget widget-search">
            <div class="search-form">
              <a (click)="onSubmitQuery()"><i class="fa fa-search searchBoxIcon"></i></a>
              <input type="search" [(ngModel)]="myQuery" class="search-field" placeholder="Search" value="" />
            </div>
          </div>
        </div>
        <!-- //Language Commented Part Starts
        <div class="col-md-3">
          <nz-select nzShowSearch style="margin-top: 15px;margin-left: 5%;" [nzAllowClear]="false"
            nzPlaceHolder="Select a language" (ngModelChange)="onChangeLangulage($event)"
            [(ngModel)]="selectedLanguage">
            <nz-option nzLabel="English" nzValue="en"></nz-option>
            <nz-option nzLabel="Hindi" nzValue="hi"></nz-option>
            <nz-option nzLabel="Telugu" nzValue="te"></nz-option>
            <nz-option nzLabel="Tamil" nzValue="ta"></nz-option>
            <nz-option nzLabel="Urdu" nzValue="ur"></nz-option>
            <nz-option nzLabel="Kannada" nzValue="kn"></nz-option>
            <nz-option nzLabel="French" nzValue="fr"></nz-option>
          </nz-select>
        </div>
        //Language Commented Part End -->
      </div>
    </div>
  </div>
  <div class="site-header-menu" style="margin-bottom: 0px;">
    <div class="container" style="max-width: 90%;">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex align-items-center" style="width: 102%;">
            <div class="site-branding flex-grow-1">
              <a routerLink="/">
                <img class="logo-img" alt="optico" src="assets/images/logo-dark.png" />
              </a>
            </div>
            <div class="site-navigation ml-auto">
              <nav class="main-menu navbar-light" style="height: 60px;">
                <div>
                  <ul class="navigation clearfix">
                    <li><a routerLink='/'>
                        {{currentLabelsObj['Home']}}
                      </a></li>
                    <li routerLinkActive="active" class="dropdown">
                      <a> {{currentLabelsObj['Funding']}} </a>
                      <ul style="width: 350px;"
                        (mouseleave)="panel.close();fellowships.close();grants.close();publicEnagement.close();">
                        <div class="row">
                          <div class="col-md-12">
                            <mat-accordion>
                              <mat-expansion-panel #panel>
                                <mat-expansion-panel-header class="disable_ripple" style="height:41px !important">
                                  <mat-panel-title>
                                    {{currentLabelsObj['Research Funding']}}
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-expansion-panel #fellowships>
                                  <mat-expansion-panel-header class="disable_ripple inner-tag">
                                    <mat-panel-title>
                                      {{currentLabelsObj['India Alliance Fellowships']}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ul class="sub_c" style="background-color: blue;">
                                    <li>
                                      <a (click)="panel.close()"
                                        routerLink="/fellowshiptype/basic-biomedical-research-fellowships"
                                        routerLinkActive="active">
                                        {{currentLabelsObj['Basic Biomedical Research Fellowships']}}
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="panel.close()"
                                        routerLink="/fellowshiptype/clinical-and-public-health-research-fellowships"
                                        routerLinkActive="active">
                                        {{currentLabelsObj['Clinical and Public Health Research Fellowships']}}
                                      </a>
                                    </li>
                                  </ul>
                                </mat-expansion-panel>
                                <mat-expansion-panel #grants>
                                  <mat-expansion-panel-header class="disable_ripple inner-tag">
                                    <mat-panel-title>
                                      {{currentLabelsObj['India Alliance Grants']}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ul class="sub_c">
                                    <li>
                                      <a (click)="panel.close()" routerLink="/team-science-grants">
                                        {{currentLabelsObj['Team Science Grants']}}
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="panel.close()" routerLink="/clinical-public-health-research-centres">
                                        {{currentLabelsObj['Clinical and Public Health Research Centres']}}
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="panel.close()"
                                        routerLink="/clinical-public-health-research-training-programme">
                                        {{currentLabelsObj['Clinical Research Training Programme']}}
                                      </a>
                                    </li>
                                  </ul>
                                </mat-expansion-panel>
                                <mat-accordion>
                                  <ul class="forApplical">
                                    <li>
                                      <a (click)="panel.close()" routerLink="/applicants/remit-and-eligibility">
                                        {{currentLabelsObj['For Applicants']}} </a>
                                    </li>
                                  </ul>
                                </mat-accordion>
                              </mat-expansion-panel>
                            </mat-accordion>
                            <!-- <mat-accordion>
                              <ul>
                                <li>
                                  <a target="_blank"
                                    href="https://www.aasciences.africa/calls/call-applications-africa-india-mobility-fund-aimf">
                                    {{currentLabelsObj['Africa-India Mobility Fund']}}</a>
                                </li>
                              </ul>
                            </mat-accordion> -->
                            <mat-accordion>
                              <!--     <ul>
                                <li>
                                  <a target="_blank" href="https://affordable-approaches-to-cancer.indiaalliance.org/">
                                    Affordable Approaches to Cancer Initiative </a>
                                </li>
			                        </ul> -->
                              <ul>
                                <li>
                                  <a (click)="panel.close()" routerLink="/india-research-management-initiative">
                                    {{currentLabelsObj['India Research Management Initiative']}} </a>
                                </li>

                                <!-- <li>
                                  <a (click)="panel.close()" routerLink="/workshops-outreach">
                                    {{currentLabelsObj['Workshops & outreach']}} </a>
                                </li> -->


                              </ul>
                            </mat-accordion>
                          </div>
                          <!-- <div class="col-md-6">
                            <mat-accordion>
                              <ul>
                                <li>
                                  <a (click)="panel.close()" routerLink="india-embo-lecture-courses">
                                    {{currentLabelsObj['India EMBO Lecture Courses']}}
                                  </a>
                                </li>
                              </ul>
                            </mat-accordion>
                            <mat-accordion>
                              <mat-expansion-panel #publicEnagement>
                                <mat-expansion-panel-header class="disable_ripple" style="height:41px !important">
                                  <mat-panel-title>
                                    {{currentLabelsObj['Public Engagement']}}
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul class="sub_c" style="background-color: blue;">
                                  <li>
                                    <a (click)="publicEnagement.close()" routerLink="public-engagement"
                                      routerLinkActive="active">
                                      {{currentLabelsObj['About Public Engagement']}}
                                    </a>
                                  </li>
                                  <li>
                                    <a (click)="publicEnagement.close()" routerLink="/india-science-media-fellowships"
                                      routerLinkActive="active">
                                      {{currentLabelsObj['India Science Media Fellowships']}}
                                    </a>
                                  </li>
                                </ul>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div> -->
                        </div>
                      </ul>
                    </li>
                    <!-- <li routerLinkActive="active" class="dropdown">
                      <a routerLinkActive="active">
                        {{currentLabelsObj['Workshops & Courses']}}
                      </a>
                      <ul style="    width: 205%;">
                        <li>
                          <a routerLink="science-communication-workshop">
                            {{currentLabelsObj['Science Communication Workshops']}}
                          </a>
                        </li>
                        <li>
                          <a routerLink="leadership-course">
                            {{currentLabelsObj['Leadership Courses']}}</a>
                        </li>
                        <li>
                          <a routerLink="developing-indian-physician-scientist-workshops">
                            {{currentLabelsObj['Developing Indian Physician Scientist Workshop']}} </a>
                        </li>
                      </ul>
                    </li> -->
                    <li routerLinkActive="active" class="dropdown">
                      <a>{{currentLabelsObj['Media']}}</a>
                      <ul>
                        <li>
                          <a routerLink="/news" routerLinkActive="active">
                            {{currentLabelsObj['News']}}</a>
                        </li>
                        <li><a routerLink="/videos"> {{currentLabelsObj['Videos']}} </a></li>
                        <li><a (click)="onMenuLink()" routerLink="/newsletters" routerLinkActive="active">
                            {{currentLabelsObj['Newsletters']}} </a>
                      </ul>
                    </li>
                    <li routerLinkActive="active" class="dropdown">
                      <a routerLinkActive="active">{{currentLabelsObj['About Us']}}</a>
                      <ul>
                        <li>
                          <a routerLink="/about-us/about-ia">{{currentLabelsObj['Organisation']}} </a>
                        </li>
                        <li>
                          <a routerLink="/about-us/funded-areas">
                            {{currentLabelsObj['Funded Areas']}} </a>
                        </li>
                        <!-- <li>
                          <a routerLink="/about-us/ceo-message">
                            CEO’s message</a>
                        </li> -->
                        <li>
                          <a routerLink="/about-us/ia-staff-and-committee-members">
                            {{currentLabelsObj['Staff and committee']}} </a>
                        </li>
                        <li>
                          <a routerLink="/about-us/annual-reports">
                            {{currentLabelsObj['Annual Reports']}} </a>
                        </li>
                        <li>
                          <a routerLink="/about-us/brand-usage-guidelines">
                            {{currentLabelsObj['Brand Usage Guidelines']}} </a>
                        </li>
                        <li>
                          <a routerLink="/fellows"> {{currentLabelsObj['India Alliance Fellows']}}</a>
                        </li>
                        <li><a routerLink="/career">{{currentLabelsObj['Careers']}} </a></li>
                        <li><a target="_blank" href="https://10years.indiaalliance.org/">{{currentLabelsObj['10 years of
                            India Alliance']}} </a>
                        </li>
                      </ul>
                    </li>
                    <li routerLinkActive="active">
                      <a routerLink="/contact" routerLinkActive="active">
                        {{currentLabelsObj['Contact Us']}} </a>
                    </li>
                    <li>
                      <a href="https://grants.indiaalliance.org/Login.aspx" target="_blank" style="color: #b72748;">
                        {{currentLabelsObj['Login to IASys']}} </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- Header Main Area End Here -->
<header class="site-header header-style-1 mobile-header">
  <div class="pre-header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center ">
        <div class="row">
          <div class="col-md-6 col-lg-6" style="width: 70%;">
            <div class="site-branding flex-grow-1" style="
            height: 60px;
            position: relative;
            top: 51px;
            text-align: center;
          ">
              <a routerLink="/">
                <img class="logo-img" alt="optico" src="assets/images/logo-dark.png" />
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-6" style="width: 30%;">
            <div class="">
              <div class="site-navigation" style="position: relative; top: 16px;">
                <nav class="main-menu navbar-expand-xl navbar-light" style="height: 60px;">
                  <div class="navbar-header">
                    <!-- Togg le Button -->
                    <button class="navbar-toggler" #menuIcon type="button" data-toggle="collapse"
                      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                      aria-label="Toggle navigation">
                      <span class="fa fa-bars"></span>
                    </button>
                  </div>
                  <div class="collapse navbar-collapse clearfix" id="navbarSupportedContent" style="
          z-index: 99999999;
          position: relative;
          background-color: #fff;
          width: 340px;
          padding: 20px;
          top: 24px;
    right: 262px;
        ">
                    <ul class="navigation clearfix">
                      <li><a routerLink='/'>
                          {{currentLabelsObj['Home']}}
                        </a></li>
                      <li routerLinkActive="active" class="dropdown">
                        <a> {{currentLabelsObj['Funding']}} </a>
                        <ul style="width: 833px;"
                          (mouseleave)="panel.close();fellowships.close();grants.close();publicEnagement.close();">
                          <div class="row">
                            <div class="col-md-6">
                              <mat-accordion>
                                <mat-expansion-panel #panel>
                                  <mat-expansion-panel-header class="disable_ripple" style="height:41px !important">
                                    <mat-panel-title>
                                      {{currentLabelsObj['Research Funding']}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <mat-expansion-panel #fellowships>
                                    <mat-expansion-panel-header class="disable_ripple inner-tag">
                                      <mat-panel-title>
                                        {{currentLabelsObj['India Alliance Fellowships']}}
                                      </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ul class="sub_c" style="background-color: blue;">
                                      <li>
                                        <a (click)="panel.close()"
                                          routerLink="/fellowshiptype/basic-biomedical-research-fellowships"
                                          routerLinkActive="active">
                                          {{currentLabelsObj['Basic Biomedical Research Fellowships']}}
                                        </a>
                                      </li>
                                      <li>
                                        <a (click)="panel.close()"
                                          routerLink="/fellowshiptype/clinical-and-public-health-research-fellowships"
                                          routerLinkActive="active">
                                          {{currentLabelsObj['Clinical and Public Health Research Fellowships']}}
                                        </a>
                                      </li>
                                    </ul>
                                  </mat-expansion-panel>
                                  <mat-expansion-panel #grants>
                                    <mat-expansion-panel-header class="disable_ripple inner-tag">
                                      <mat-panel-title>
                                        {{currentLabelsObj['India Alliance Grants']}}
                                      </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ul class="sub_c">
                                      <li>
                                        <a (click)="panel.close()" routerLink="/team-science-grants">
                                          {{currentLabelsObj['Team Science Grants']}}
                                        </a>
                                      </li>
                                      <li>
                                        <a (click)="panel.close()"
                                          routerLink="/clinical-public-health-research-centres">
                                          {{currentLabelsObj['Clinical and Public Health Research Centres']}}
                                        </a>
                                      </li>
                                      <li>
                                        <a (click)="panel.close()"
                                          routerLink="/clinical-public-health-research-training-programme">
                                          {{currentLabelsObj['Clinical Research Training Programme']}}
                                        </a>
                                      </li>
                                    </ul>
                                  </mat-expansion-panel>
                                  <mat-accordion>
                                    <ul class="forApplical">
                                      <li>
                                        <a (click)="panel.close()" routerLink="/applicants/remit-and-eligibility">
                                          {{currentLabelsObj['For Applicants']}} </a>
                                      </li>
                                    </ul>
                                  </mat-accordion>
                                </mat-expansion-panel>
                              </mat-accordion>
                              <!-- <mat-accordion>
                                <ul>
                                  <li>
                                    <a target="_blank"
                                      href="https://www.aasciences.africa/calls/call-applications-africa-india-mobility-fund-aimf">
                                      {{currentLabelsObj['Africa-India Mobility Fund']}}</a>
                                  </li>
                                </ul>
                              </mat-accordion> -->
                              <mat-accordion>
                                <!-- <ul>
                                  <li>
                                    <a target="_blank" href="https://affordable-approaches-to-cancer.indiaalliance.org/">
                                      Affordable Approaches to Cancer Initiative </a>
                                  </li>
                                </ul> -->
                                <ul>
                                  <li>
                                    <a (click)="panel.close()" routerLink="/india-research-management-initiative">
                                      {{currentLabelsObj['India Research Management Initiative']}} </a>
                                  </li>
                                  <li>
                                    <a (click)="panel.close()" routerLink="/workshops-outreach">
                                      {{currentLabelsObj['Workshops & outreach']}} </a>
                                  </li>

                                </ul>
                              </mat-accordion>
                            </div>
                            <div class="col-md-6">
                              <mat-accordion>
                              </mat-accordion>
                              <mat-accordion>
                                <ul>
                                  <li>
                                    <a (click)="panel.close()" routerLink="india-embo-lecture-courses">
                                      {{currentLabelsObj['India EMBO Lecture Courses']}}
                                    </a>
                                  </li>
                                </ul>
                              </mat-accordion>
                              <mat-accordion>
                                <mat-expansion-panel #publicEnagement>
                                  <mat-expansion-panel-header class="disable_ripple" style="height:41px !important">
                                    <mat-panel-title>
                                      {{currentLabelsObj['Public Engagement']}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ul class="sub_c" style="background-color: blue;">
                                    <li>
                                      <a (click)="publicEnagement.close()" routerLink="public-engagement"
                                        routerLinkActive="active">
                                        {{currentLabelsObj['About Public Engagement']}}
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="publicEnagement.close()" routerLink="/india-science-media-fellowships"
                                        routerLinkActive="active">
                                        {{currentLabelsObj['India Science Media Fellowships']}}
                                      </a>
                                    </li>
                                  </ul>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li routerLinkActive="active" class="dropdown">
                        <a routerLinkActive="active">
                          {{currentLabelsObj['Workshops & Courses']}}
                        </a>
                        <ul style="    width: 205%;">
                          <li>
                            <a routerLink="science-communication-workshop">
                              {{currentLabelsObj['Science Communication Workshops']}}
                            </a>
                          </li>
                          <li>
                            <a routerLink="leadership-course">
                              {{currentLabelsObj['Leadership Courses']}}</a>
                          </li>
                          <li>
                            <a routerLink="developing-indian-physician-scientist-workshops">
                              {{currentLabelsObj['Developing Indian Physician Scientist Workshop']}} </a>
                          </li>
                        </ul>
                      </li>
                      <li routerLinkActive="active" class="dropdown">
                        <a>{{currentLabelsObj['Media']}}</a>
                        <ul>
                          <li>
                            <a routerLink="/news" routerLinkActive="active">
                              {{currentLabelsObj['News']}}</a>
                          </li>
                          <li><a routerLink="/videos"> {{currentLabelsObj['Videos']}} </a></li>
                          <li><a (click)="onMenuLink()" routerLink="/newsletters" routerLinkActive="active">
                              {{currentLabelsObj['Newsletters']}} </a>
                        </ul>
                      </li>
                      <li routerLinkActive="active" class="dropdown">
                        <a routerLinkActive="active">{{currentLabelsObj['About Us']}}</a>
                        <ul>
                          <li>
                            <a routerLink="/about-us/about-ia">{{currentLabelsObj['Organisation']}} </a>
                          </li>
                          <li>
                            <a routerLink="/about-us/funded-areas">
                              {{currentLabelsObj['Funded Areas']}} </a>
                          </li>
                          <!-- <li>
                            <a routerLink="/about-us/ceo-message">
                              CEO’s message</a>
                          </li> -->
                          <li>
                            <a routerLink="/about-us/ia-staff-and-committee-members">
                              {{currentLabelsObj['Staff and committee']}} </a>
                          </li>
                          <li>
                            <a routerLink="/about-us/annual-reports">
                              {{currentLabelsObj['Annual Reports']}} </a>
                          </li>
                          <li>
                            <a routerLink="/about-us/brand-usage-guidelines">
                              {{currentLabelsObj['Brand Usage Guidelines']}} </a>
                          </li>
                          <li>
                            <a routerLink="/fellows"> {{currentLabelsObj['India Alliance Fellows']}}</a>
                          </li>
                          <li><a routerLink="/career">{{currentLabelsObj['Careers']}} </a></li>
                          <li><a target="_blank" href="https://10years.indiaalliance.org/">{{currentLabelsObj['10 years
                              of India Alliance']}} </a>
                          </li>
                        </ul>
                      </li>
                      <li routerLinkActive="active">
                        <a routerLink="/contact" routerLinkActive="active">
                          {{currentLabelsObj['Contact Us']}} </a>
                      </li>
                      <li>
                        <a href="https://grants.indiaalliance.org/Login.aspx" target="_blank" style="color: #b72748;">
                          {{currentLabelsObj['Login to IASys']}} </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="site-header-menu" style="margin-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex align-items-center" style="width: 102%;">
            <div class="row" style="width: 100%;">
              <div class="col-md-12 width-col-12">
                <div class="widget widget-search">
                  <form class="search-form">
                    <a><i class="fa fa-search"></i></a>
                    <input type="search" class="search-field" placeholder="Search" value="" />
                  </form>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>